import { createReducer, on } from '@ngrx/store';
import { Portal } from '../../models';
import * as pageActions from '../actions/active-portal.action';

export interface ActivePortalState {
  activePortal: Portal | null;
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ActivePortalState = {
  activePortal: null,
  loaded: false,
  loading: false,
};

export const ActivePortalReducer = createReducer(
  initialState,
  on(pageActions.LoadActivePortal, (state: ActivePortalState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadActivePortalSuccess, (state: ActivePortalState, { activePortal }) => {
    return {
      ...state,
      activePortal,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadActivePortalFail, (state: ActivePortalState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getActivePortal = (state: ActivePortalState) => state.activePortal;
export const getActivePortalLoading = (state: ActivePortalState) => state.loading;
export const getActivePortalLoaded = (state: ActivePortalState) => state.loaded;
