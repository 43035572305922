import * as fromActivePortal from './active-portal.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'portals';

export interface PortalState {
  activePortal: fromActivePortal.ActivePortalState;
}

export const reducers: ActionReducerMap<PortalState> = {
  activePortal: fromActivePortal.ActivePortalReducer,
};

export const getPortalState = createFeatureSelector<PortalState>(featureKey);

export const metaReducers: MetaReducer<PortalState>[] = !environment.production ? [] : [];
