import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPortals from '../reducers/active-portal.reducer';

export const getActivePortalState = createSelector(fromFeature.getPortalState, (state: fromFeature.PortalState) => state.activePortal);

export const getActivePortal = createSelector(getActivePortalState, fromPortals.getActivePortal);
export const getActivePortalLoaded = createSelector(getActivePortalState, fromPortals.getActivePortalLoaded);
export const getActivePortalLoading = createSelector(getActivePortalState, fromPortals.getActivePortalLoading);
